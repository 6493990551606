import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import BlogSection from './components/BlogSection';

const MainPage = () => {
  const defaultTheme = createTheme({ palette: { mode: 'light' } });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar />
      <Hero />
      <Box
        sx={{
          backgroundRepeat: 'no-repeat',
          bgcolor: 'aliceblue',
          backdropFilter: 'blur(24px)',
          boxShadow:
            '0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)',
        }}
      >
        <Divider />
        <Testimonials />
        <Divider />
        <FAQ />
        <Divider />
        <BlogSection />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default MainPage;
