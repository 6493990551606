import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  styled,
} from '@mui/material';

const blogPosts = [
  {
    key: 1,
    title: 'Caria Dentară la Copii: Un Inamic Silențios',
    imageAlt: 'articol-dentar-1',
    imagePath: '/blog_1.jpeg',
  },
  {
    key: 2,
    title: 'Respirația orală vs. Înfățișarea facială, postură și succes școlar',
    imageAlt: 'articol-dentar-2',
    imagePath: '/blog_2.jpeg',
  },
  {
    key: 3,
    title: '2 cele mai frecvente întrebări despre aparatul dentar',
    imageAlt: 'articol-dentar-3',
    imagePath: '/blog_3.jpeg',
  },
  {
    key: 4,
    title: 'Tratarea cariilor la dinții de lapte: moft sau necesitate?',
    imageAlt: 'articol-dentar-4',
    imagePath: '/blog_4.jpeg',
  },
];

const CardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(4, 0),
}));

const BlogSection = () => {
  return (
    <Container sx={{ paddingBottom: '40px' }} id='blogs'>
      <SectionTitle variant='h4' color='primary.main'>
        Articole
      </SectionTitle>
      <Grid container spacing={3}>
        {blogPosts.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post.key}>
            <Card>
              <CardActionArea component={Link} to={`/blog/${post.key}`}>
                <CardMedia
                  component='img'
                  alt={post.imageAlt}
                  height='140'
                  image={post.imagePath}
                />
                <CardContent>
                  <CardTitle variant='h6' color='info.light'>
                    {post.title}
                  </CardTitle>
                  <Typography
                    variant='body2'
                    color='primary.light'
                    component='p'
                  >
                    Click aici pentru a citi mai mult
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BlogSection;
