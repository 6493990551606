import React, { useState } from 'react';
import { Container, Typography, Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import CarouselComponent from './CarouselComponent';

export const SECTIONS = {
  CLINIC: 'clinic',
  LAB: 'lab',
  RECEPTION: 'reception',
  CONFERENCES: 'conferences',
};

const SECTIONS_ARRAY = [
  { label: 'Clinica', section: SECTIONS.CLINIC },
  { label: 'Laborator', section: SECTIONS.LAB },
  { label: 'Receptie', section: SECTIONS.RECEPTION },
  { label: 'Conferinte', section: SECTIONS.CONFERENCES },
];

const OurClinicPage = () => {
  const navigate = useNavigate();

  const [currentSection, setCurrentSection] = useState(SECTIONS.OUTSIDE);

  const handleSectionChange = (section) => {
    setCurrentSection(section);
  };

  return (
    <Container maxWidth='md'>
      <Typography
        component='h2'
        variant='h4'
        color='primary.main'
        textAlign='center'
        marginBottom='24px'
        marginTop='24px'
      >
        Our Clinic
      </Typography>
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '12px',
          padding: '12px',
        }}
      >
        {SECTIONS_ARRAY.map((item, index) => (
          <Button
            key={index}
            onClick={() => handleSectionChange(item.section)}
            variant={currentSection === item.section ? 'contained' : 'outlined'} // Changes style based on selected
          >
            {item.label}
          </Button>
        ))}
      </Container>
      <CarouselComponent currentSection={currentSection} />
      <Divider />
      <Button
        variant='text'
        color='info'
        size='small'
        style={{ position: 'absolute', top: 0, left: 0, fontWeight: '600' }}
        onClick={() => navigate(-1)} // Goes back one step in the history stack}
      >
        Inapoi
      </Button>
      <Footer />
    </Container>
  );
};

export default OurClinicPage;
