import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import MainPage from './MainPage';
import BlogPage from './components/BlogPage';
import OurClinicPage from './components/OurClinicPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<MainPage />} />
        <Route path='/blog/:id' element={<BlogPage />} />
        <Route path='/ourClinic' element={<OurClinicPage />} />
      </Routes>
    </Router>
  );
};

export default App;
