import * as React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ_CARDS = [
  {
    key: 'panel1',
    summary: 'Care sunt orele de funcționare ale clinicii?',
    details:
      'Clinica noastră este deschisă de Luni până Vineri, între orele 9:00 și 17:00. Pentru programări, vă rugăm să ne contactați în avans.',
  },
  {
    key: 'panel2',
    summary: 'Cât de avansată este echipamentul din clinica dvs.?',
    details:
      'Clinica noastră este dotată cu tehnologie de ultimă generație, inclusiv echipamente de radiologie digitală, sisteme de planificare și simulare a tratamentelor, precum și tehnologie avansată de sterilizare pentru a asigura siguranța și confortul pacienților noștri.',
  },
  {
    key: 'panel3',
    summary:
      'Care este adresa fizică a clinicii Baltean Ortodontics din Galați?',
    details:
      'Clinica noastră este situată în centrul orașului Galați, la adresa Strada Serii 32, Galați. Avem și un spațiu de parcare dedicat pentru confortul pacienților noștri.',
  },
  {
    key: 'panel4',
    summary: 'Ce metode de plată acceptați?',
    details:
      'Acceptăm plata în numerar, card bancar și prin intermediul asigurărilor medicale private. De asemenea, oferim opțiuni de plată în rate pentru anumite tratamente.',
  },
  {
    key: 'panel5',
    summary:
      'Cum pot lua legătura cu clinica pentru a programa o consultație sau pentru alte întrebări?',
    details:
      'Puteți să ne sunați la numărul de telefon al clinicii 0236433909, să ne trimiteți un e-mail la adresa drbaltean@yahoo.com.',
  },
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id='faq'
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component='h2'
        variant='h4'
        color='primary.main'
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        FAQ
      </Typography>
      <Box sx={{ width: '100%' }}>
        {FAQ_CARDS.map((item) => (
          <Accordion
            key={item.key}
            expanded={expanded === item.key}
            onChange={handleChange(item.key)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${item.key}-content`}
              id={`${item.key}-header`}
            >
              <Typography component='h3' variant='subtitle2' color='info.light'>
                {item.summary}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant='body2'
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                color='primary.light'
              >
                {item.details}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
