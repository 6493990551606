import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';

export default function Hero() {
  return (
    <Box
      id='title-section'
      sx={() => ({
        width: '100%',
        backgroundImage: `url('/exterior_main.jpg')`,
        backgroundRepeat: 'no-repeat',
        bgcolor: 'aliceblue',
        height: {xs: '100%', sm: '800px'},
        backdropFilter: 'blur(24px)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        boxShadow:
          '0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          height: '100%'
        }}
      >
        <Stack
          spacing={2}
          alignItems='center'
          useFlexGap
          sx={{ width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant='h1'
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            <Fade in timeout={3500}>
              <Typography
                component='span'
                variant='h1'
                sx={{
                  textAlign: 'center',
                  fontSize: 'inherit',
                  fontWeight: '500',
                  color: '#ccf5ff',
                }}
              >
                Baltean Ortodontics
              </Typography>
            </Fade>
          </Typography>
          <Grow in timeout={3500}>
            <Typography
              textAlign='center'
              color='info.light'
              sx={{
                width: { sm: '100%', md: '80%' },
                color: '#FFFFFF',
                fontWeight: 'bold',
              }}
            >
              Destinația ta pentru un zâmbet sănătos și strălucitor.
            </Typography>
          </Grow>
        </Stack>
      </Container>
    </Box>
  );
}
