import React from 'react';
import {
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Divider,
  Button,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Footer from './Footer';

const BlogTitle = styled(Typography)({
  marginTop: '32px',
});

const BlogContent = styled(CardContent)({
  paddingBottom: '0 !important', // Override default padding
});

const BLOG_POSTS = {
  1: {
    title: 'Caria Dentară la Copii: Un Inamic Silențios',
    content: `
    Cariile dentare rămân o problemă comună în rândul copiilor, reprezentând mult mai mult decât o simplă neplăcere estetică. Ele pot cauza durere intensă, probleme de nutriție și pot avea un impact negativ asupra dezvoltării dentare pe termen lung. Este crucial să înțelegem că prevenția începe devreme, chiar din momentul în care primul dinte face apariția.
    ✅ Prevenția: Cheia Reușitei
    Un program regulat de igienă orală și vizite periodice la dentist sunt esențiale pentru a preveni cariile dentare. Fluorida, care poate fi găsită în paste de dinți și, în unele cazuri, în apa de la robinet, este un aliat valoros în lupta împotriva cariilor. Periajul dentar de două ori pe zi și folosirea aței dentare sunt de asemenea recomandate. Părinții trebuie să fie conștienți și de rolul alimentației în sănătatea orală; evitarea zahărului și a alimentelor acide poate ajuta semnificativ în prevenția cariilor.
    ✅ Tratamentul Cariilor
    În cazul în care cariile au ajuns la un stadiu avansat, este imperativ să se recurgă la tratament pentru a evita complicații mai serioase cum ar fi abcesul dentar sau afectarea structurii dintelui. Opțiunile de tratament variază de la plombe până la coroane dentare, în funcție de severitatea cazului. În cazuri extreme, extracția poate fi necesară. Este esențial ca părinții să caute consult medical de specialitate pentru a determina cea mai bună cale de acțiune.
    Prin abordarea proactivă a prevenirii și tratării cariilor la copii, putem contribui la asigurarea unei sănătăți orale optime pentru generațiile viitoare. 🦷
    📍 Baltean Ortodontix
    `,
    imagePath: '/blog_1.jpeg',
  },
  2: {
    title: '2 cele mai frecvente întrebări despre aparatul dentar',
    content: `
    Trebuie să îmi tratez cariile dentare înainte de punerea aparatului?
    ✅ Este recomandat să le tratezi, dar cariile pot fi tratate și în timpul tratamentului ortodontic, deoarece adesea după alinierea dinților încalecați descoperim numeroase cãrii, deoarece periuța și ata dentară nu puteau pătrunde între dinții îngesuiți. Medicii noștri sunt obișnuiți să trateze cariile când aparatul este deja în gura pacientului. Când pacientul vine la control, scoatem arcurile și lăsăm doar bracketurile pe dinți pentru ca medicul să poată curăța și plomba dinte fără a fi împiedicat de nimic, apoi punem arcurile la loc și continuăm tratamentul ortodontic.
    Trebuie să îmi scot molarii de minte înainte de punerea aparatului dentar?
    ✅ Deși molarii de minte pot cauza inghesuiri ale dinților și pot afecta rezultatele tratamentului cu aparat dentar atunci când încearcă să erupă, nu întotdeauna este recomandată extragerea lor. Dacă inghesuirile dentare sunt atât de mari încât trebuie extrasi premolari pentru a face spațiu pentru ceilalți dinți, molarii nu mai exercită atât de multã presiune, deoarece s-a obținut spațiu. Fiecare caz trebuie analizat de medicul specialist ortodont, care va decide dacã este necesară extragerea molariilor de minte. Extracția lor este posibilã în timpul tratamentului ortodontic sau imediat după înlăturarea aparatului dentar.
    📍 Baltean Ortodontix
    `,
    imagePath: '/blog_2.jpeg',
  },
  3: {
    title:
      'Respirația orală vs. Înfățișarea facială, postură și succes școlar 🌬️',
    content: `
    Dezvoltarea complexului facial este influențată de mulți factori, unul dintre cei mai importanți fiind RESPIRAȚIA!
    Respirația este una dintre funcțiile fiziologice care, în mod normal, ar trebui să se desfășoare doar pe NAS, atât ziua, cât și noaptea.
    Respirația orală sau mixtă este considerată anormală, iar abaterea de la funcția normală poate avea consecințe asupra dezvoltării structurii faciale.
    Ce se întâmplă în cazul respirației orale?
    În timpul inspirului pe gură, limba se află în poziție joasă, ocupând adesea spațiul dintre dinții din față, ceea ce poate duce la subdezvoltarea maxilarului superior. De asemenea, presiunea aerului pe cerul gurii în timpul inspirului poate îngusta maxilarul și poate împiedica erupția normală a dinților, cauzând probleme dentare. Mai mult decât atât, cantitatea de oxigen care ajunge în plămâni este redusă, ceea ce poate duce la o stare de oboseală, somnolență și scădere a atenției la școală.
    Cum putem observa respirația orală?
    Părinții ar trebui să fie atenți la semnele respirației orale la copii: fața poate avea un aspect alungit și îngust, pot apărea cearcăne sub ochi, gura poate fi deschisă ușor, buzele uscate și bărbia retrasă. Uneori, copilul poate ține capul ușor înclinat înainte.
    Ce trebuie făcut dacă copilul respiră pe gură?
    Tratamentul pentru respirația orală începe cu diagnosticarea cauzei!
    Primul pas este să consultați un medic specialist ORL pentru a verifica dacă problema nu are legătură cu căile respiratorii (adenoizi, deviere de sept nazal, alergii).
    În continuare, cu avizul medicului ORL, consultați un ortodont pentru a planifica tratamentul care să permită dezvoltarea corectă a complexului facial.
    📍 Baltean Ortodontix
    `,
    imagePath: '/blog_3.jpeg',
  },
  4: {
    title: 'Tratarea cariilor la dinții de lapte: moft sau necesitate? 🤔',
    content: `
    Tratarea cariilor dinților temporari este văzută ades ca opțională. “Dacă nu doare, nu e așa de rău.”
Atât părinții, cât și responsabilii din domeniul sănătății orale consideră uneori că rezolvarea cariilor dentare în dentiția temporară este o opțiune sau reprezintă o alternativă. “Se poate rezolva, dar se poate și amâna. La urma urmei, oricum o să cadă acești dinți”

Persistența cariilor pe dinții temporari aduce cu sine o serie de inconveniențe și riscuri.
Din problemele imediat vizibile se remarcă funcția masticației. În primul rând copilul are nevoie de dinți integri pentru a putea mânca nestingherit de faptul că are într-o parte a gurii proprii o gaură care fie se umple cu mâncare, fie nu îi dă voie să mănânce din pricina gingiei inflamate în zona respectivă.
Apoi, cariile netratate ajung în punctul de a produce durere. Aici nu este nicio nevoie de argumente. Orice om știe că atunci când începe să îl doară, nu mai poate lăsa lucrurile la fel.

✅ Totuși, a preveni o infecție dentară este cu mult mai prielnic decât a o trata în ultimul moment.
Noi lucrăm în ortodonție îndeosebi.

Obiectivul echipei noastre este să ne asigurăm că dinții vor crește nu doar necariați, ci și la locul lor. Trebuie amintit că dinții temporari mențin spațiul pentru dinții permanenți. Oriunde pierdem un dinte temporar înainte de vreme, posibilitățile sunt mari să pierdem și spațiul pe care acesta îl menținea pe arcadă.

Așadar, avem nevoie sa păstrăm dinții temporari sanatosi până ce sunt împinși de dentiția permanentă, iar nu de cariile netratate.
Rămânem în zona ortodontică: volumul fiziologic ocupat de fiecare dinte temporar în parte nu menține spațiul pe arcadă doar pentru succesorul său permanent, ci și în sens vertical păstrează echilibrul. Astfel, prezența lor în cavitatea bucală până ce le vine timpul pentru a fi înlocuiți facilitează dezvoltarea echilibrată a dinților.
    📍 Baltean Ortodontix
    `,
    imagePath: '/blog_4.jpeg',
  },
};

const BlogPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const blogPost = BLOG_POSTS[id];

  if (!blogPost) {
    return <Typography variant='h4'>Blog not found!</Typography>;
  }

  const contentLines = blogPost.content.split('\n').map((line, index) => (
    <Typography key={index} variant='body1' paragraph color='primary.light'>
      {line.trim()}
    </Typography>
  ));

  return (
    <Container maxWidth='md'>
      <BlogTitle variant='h4' gutterBottom color='info.light'>
        {blogPost.title}
      </BlogTitle>
      <Card>
        <BlogContent>{contentLines}</BlogContent>
        <CardMedia
          component='img'
          src={blogPost.imagePath}
          alt={blogPost.title}
        />
      </Card>
      <Button
        variant='text'
        color='info'
        size='small'
        style={{ position: 'absolute', top: 0, left: 0, fontWeight: '600' }}
        onClick={() => navigate(-1)} // Goes back one step in the history stack}
      >
        Inapoi
      </Button>
      <Divider />
      <Footer />
    </Container>
  );
};

export default BlogPage;
