import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Fade, styled } from '@mui/material';

const ServiceCard = styled(Card)({
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const userTestimonials = [
  {
    name: 'Consultatie dentară',
    testimonial:
      'Evaluare completă a sănătății dentare și a nevoilor individuale ale pacientului, inclusiv examinare orală și radiografii.',
  },
  {
    name: 'Igiena dentară profesională',
    testimonial:
      'Curățarea profundă a dinților pentru îndepărtarea plăcii bacteriene și a tartrului, menținând sănătatea gingiilor și a dinților.',
  },
  {
    name: 'Obturatii dentare (plombe)',
    testimonial:
      'Restaurarea dinților afectați de carii sau alte leziuni, folosind materiale sigure și durabile pentru a restabili funcția și aspectul natural al dintelui.',
  },
  {
    name: 'Tratamente de canal',
    testimonial:
      'Tratament endodontic pentru salvarea dinților grav afectați de infecții sau leziuni, eliminând durerea și salvând structura dentară existentă.',
  },
  {
    name: 'Coroane dentare',
    testimonial:
      'Restaurarea și protejarea dinților afectați de leziuni sau carii extinse, prin acoperirea completă a dintelui cu o coroană personalizată.',
  },
  {
    name: 'Aparat dentar (ortodontie)',
    testimonial:
      'Corectarea poziției incorecte a dinților și a problemelor de aliniere a maxilarelor, pentru un zâmbet drept și sănătos.',
  },
  {
    name: 'Proteze dentare',
    testimonial:
      'Rezolvarea problemelor legate de pierderea dinților, oferind proteze dentare personalizate pentru a restabili funcția și estetica zâmbetului.',
  },
  {
    name: 'Implanturi dentare',
    testimonial:
      'Înlocuirea dinților lipsă cu implanturi dentare permanente, oferind o soluție durabilă și naturală pentru restaurarea zâmbetului.',
  },
  {
    name: 'Chirurgie dentară',
    testimonial:
      'Tratamentul și managementul intervențiilor chirurgicale dentare, inclusiv extracții dentare și alte proceduri chirurgicale necesare pentru sănătatea orală a pacientului.',
  },
];

// Services
export default function Testimonials() {
  return (
    <Container
      id='testimonials'
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component='h2' variant='h4' color='primary.main'>
          Servicii disponibile
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Fade in timeout={index * 1000} key={index}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ display: 'flex' }}
            >
              <ServiceCard
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  bgcolor: '#00509E',
                  flexGrow: 1,
                  p: 1,
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      pr: 2,
                    }}
                  >
                    <CardHeader
                      title={testimonial.name}
                      titleTypographyProps={{ color: 'info.light' }}
                    />
                  </Box>
                  <Typography variant='body2' color='primary.light'>
                    {testimonial.testimonial}
                  </Typography>
                </CardContent>
              </ServiceCard>
            </Grid>
          </Fade>
        ))}
      </Grid>
    </Container>
  );
}
