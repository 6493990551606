import * as React from 'react';

import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Box, Grid, styled } from '@mui/material';
// import WorkCalendar from './WorkCalendar';

const ContactContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

const InfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}));

export default function Footer() {
  return (
    <ContactContainer id='contact'>
      <Title variant='h4' color='primary.main'>
        Contact
      </Title>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <InfoBox>
            <Typography
              variant='h6'
              gutterBottom
              component='span'
              sx={{
                fontSize: 'inherit',
                color: 'primary.main',
              }}
            >
              Email
            </Typography>
            <Typography variant='body1'>
              <Link href='mailto:drbaltean@yahoo.com'>drbaltean@yahoo.com</Link>
            </Typography>
          </InfoBox>
          <InfoBox>
            <Typography
              variant='h6'
              gutterBottom
              component='span'
              sx={{
                fontSize: 'inherit',
                color: 'primary.main',
              }}
            >
              Telefon
            </Typography>
            <Typography variant='body1'>
              <Link href='tel:0236433909'>0236-433-909</Link>
            </Typography>
          </InfoBox>
          <InfoBox>
            <Typography
              variant='h6'
              gutterBottom
              component='span'
              sx={{
                fontSize: 'inherit',
                color: 'primary.main',
              }}
            >
              Adresa
            </Typography>
            <Typography variant='body1'>
              <Link href='https://maps.app.goo.gl/Nbrzb3iqxgFGicgD7'>
                Strada Serii 32, Galați.
              </Link>
            </Typography>
          </InfoBox>
        </Grid>
        <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2799.403386062811!2d28.0440317!3d45.4415268!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b6df982ecaf2b3%3A0xfaccfdee748854e8!2sOrtodontix!5e0!3m2!1sen!2sro!4v1716043835288!5m2!1sen!2sro'
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            title='baltean-ortodontics-map'
          ></iframe>
        </Grid>
      </Grid>
      {/* <Box mt={4} width='100%'>
        <WorkCalendar />
      </Box> */}
    </ContactContainer>
  );
}
